import { PageResult, SolutionFilter } from '../../models';
import { SolutionIntrospect } from '../../models/solution/solution-introspect.model';
import { Solution } from '../../models/solution/solution.model';
import { CCApiService } from './api-service';
import { UIConfig } from './config-service';

export class SolutionApiService {
  static ApiService: CCApiService;
  static introspectApi: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.introspectApi = `${this.apiBasePath}/introspection/solutions`;
  }

  static async getSolutions(filter: SolutionFilter = {}): Promise<PageResult<Solution>> {
    const url = this.getSolutionApiUrl({ filter });
    return this.ApiService.performFetch(url);
  }

  static async getSolution(customerId: string, solutionId: string): Promise<Solution> {
    const url = this.getSolutionApiUrl({ customer: customerId, solution: solutionId });
    return this.ApiService.performFetch(url);
  }

  static async createSolution(customerId: string, solution: Solution): Promise<Solution> {
    const url = this.getSolutionApiUrl({ customer: customerId });
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(solution),
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }

  static async updateSolution(customerId: string, solution: Solution): Promise<Solution> {
    const url = this.getSolutionApiUrl({ customer: customerId, solution: solution.id });
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(solution),
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }

  static lockSolution(customerId: string, solution: Solution, payload: { reason: string }) {
    const url = this.getSolutionApiUrl({ customer: customerId, solution: solution.id });
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    return this.ApiService.performFetch(`${url}/locks`, fetchOptions);
  }

  static unlockSolution(customerId: string, solution: Solution) {
    const url = this.getSolutionApiUrl({ customer: customerId, solution: solution.id });
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(`${url}/locks`, fetchOptions);
  }

  static async migrateSolution(
    customerId: string,
    solution: Solution,
    payload: { destination: string }
  ): Promise<Solution> {
    const url = this.getSolutionApiUrl({ customer: customerId, solution: solution.id });
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    return this.ApiService.performFetch(`${url}/migrations`, fetchOptions);
  }

  static deleteSolution(solution: Solution) {
    const url = this.getSolutionApiUrl({ customer: solution.customer.id, solution: solution.id });
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }

  static async getCount(filter: SolutionFilter = {}): Promise<number> {
    const url = this.getRollupApiUrl(filter);
    return this.ApiService.performFetch(url).then(count => count);
  }

  static getSolutionApiUrl = (opts: ApiUrlOptions = {}): string => {
    let url = this.apiBasePath;
    if (opts.customer) {
      url += `/customers/${opts.customer}`;
    }
    url += '/solutions';
    if (opts.solution) {
      url += `/${opts.solution}`;
    }
    url += SolutionApiService.getQueryString(opts.filter);
    return url;
  };

  static getRollupApiUrl = (opts: ApiUrlOptions = {}) => {
    let url = `${SolutionApiService.getSolutionApiUrl()}/rollup`;
    url += SolutionApiService.getQueryString(opts.filter);
    return url;
  };

  static getQueryString = (filter: SolutionFilter = {}) => {
    const params = new URLSearchParams(filter);
    if (params && params.toString()) {
      return `?${params}`;
    }
    return '';
  };

  static introspect(): Promise<SolutionIntrospect> {
    return this.ApiService.performFetch(this.introspectApi);
  }

  static async editApplicationUserRoles(application: Solution, roles: any): Promise<void> {
    const updateAppApi = `${this.apiBasePath}/customers/${application.customer.id}/solutions/${application.id}/users`;
    const fetchOptions = {
      method: 'PATCH',
      body: JSON.stringify(roles),
    };
    return this.ApiService.performFetch(updateAppApi, fetchOptions);
  }
}

interface ApiUrlOptions {
  customer?: string;
  solution?: string;
  filter?: SolutionFilter;
}
